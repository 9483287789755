import Button from 'components/Button'
import Image from 'components/Image'
import Page from 'components/Page'
import Text from 'components/Text'
import { ColumnCenter } from 'layout/Components/Column'
import React from 'react'
import { Trans } from 'react-i18next'

const Error = () => {
  return (
    <Page my="16vh" height="100%">
      <ColumnCenter>
        <Image src="/images/error.png" width={512} height={320} alt="error-page" />
        <Text fontWeight={700} fontSize="20px" my="40px">
          <Trans>Something Went Wrong.!</Trans>
        </Text>
        <Button
          as={'a'}
          title="Back to home"
          href="/"
          style={{
            fontWeight: 700,
          }}
          width="100%"
          maxWidth="360px"
        >
          <Trans>Home</Trans>
        </Button>
      </ColumnCenter>
    </Page>
  )
}

export default Error
