import BigNumber from 'bignumber.js'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { BIG_ZERO } from 'config/constants/number'
import { TokenAmount } from '..'

export enum BonusUserTypeEnums {
  All = 10,
  NewUser = 1,
}

export enum BonusTagEnums {
  CanActive,
  OnlyUse,
  NoDeposit,
}

export enum BonusStatusEnums {
  Available = 1,
  Used = 10,
  Active = -1,
  Invalid = -2,
}

export enum WageringBonusStatusEnums {
  Available,
  WaitingDeposit,
  WaitingSpin,
  InProgressWagering,
  Claimed,
  Cancelled,
}

export enum ApiBonusStatusEnums {
  WaitApply = 1,
  WaitDeposit = 2,
  WaitFRBResister = 3,
  WaitFRBPlay = 4,
  WaitBetCollect = 5,
  BetCollecting = 6,
  WaitClaim = 7,
  Applied = 10,
  UserCancel = -1,
  Cancel = -2,
  WaitAddBonusLock = 8,
}

export interface IBonusCondition {
  userType?: BonusUserTypeEnums
  expiryDate?: number
  maxUserLevel?: number
  minUserLevel?: number
}

export class BonusCondition implements IBonusCondition {
  userType?: BonusUserTypeEnums
  expiryDate?: number
  maxUserLevel?: number
  minUserLevel?: number

  constructor(condition: IBonusCondition) {
    this.userType = condition.userType
    this.expiryDate = condition.expiryDate
    this.maxUserLevel = condition.maxUserLevel
    this.minUserLevel = condition.minUserLevel
  }

  public get isExpired() {
    return new Date().getTime() > this.expiryDate
  }

  public validateUserLevelEligibility(level: number) {
    return level >= this.minUserLevel && level <= this.maxUserLevel
  }
}

export type BonusInfomation = {
  image: string
  name?: string
  badge?: string
  redeemDate?: number
  activeDate?: number
  instruction?: string
}

export interface IUserBonus {
  id: number
  info: BonusInfomation
  condition: IBonusCondition
  apiStatus?: ApiBonusStatusEnums
  tags?: BonusTagEnums[]
  depositId?: number
}

export abstract class UserBonus {
  id: number

  info: BonusInfomation
  condition: BonusCondition

  status?: BonusStatusEnums
  depositId?: number
  apiStatus?: ApiBonusStatusEnums
  tags?: BonusTagEnums[]

  constructor(bonus: IUserBonus) {
    this.id = bonus.id
    this.info = bonus.info
    this.condition = new BonusCondition(bonus.condition)
    this.apiStatus = bonus.apiStatus

    this.status =
      bonus.apiStatus === ApiBonusStatusEnums.WaitApply
        ? BonusStatusEnums.Available
        : bonus.apiStatus === ApiBonusStatusEnums.Cancel ||
          bonus.apiStatus === ApiBonusStatusEnums.UserCancel ||
          bonus.apiStatus === ApiBonusStatusEnums.Applied
        ? BonusStatusEnums.Used
        : BonusStatusEnums.Active
  }
}

export class FreeHUSDLockBonus extends UserBonus {
  readonly tags = [BonusTagEnums.OnlyUse]
  amount: string

  constructor(bonus: IUserBonus, amount: string) {
    super(bonus)
    this.amount = amount
  }
}

export class FreeLuckyspinBonus extends UserBonus {
  readonly tags = [BonusTagEnums.OnlyUse]
  amount: number

  constructor(bonus: IUserBonus, amount: number) {
    super(bonus)
    this.amount = amount
  }
}

export class HUSDUnLockBoosterBonus extends UserBonus {
  readonly tags = [BonusTagEnums.CanActive]

  extraUnlockPercent: number
  duration: number

  constructor(bonus: IUserBonus, duration: number, percent: number) {
    super(bonus)
    this.extraUnlockPercent = percent
    this.duration = duration
  }
}

export class LevelUpBonus extends UserBonus {
  readonly tags = [BonusTagEnums.CanActive]
  extraLevels: number
  maxLevels: number
  duration: number

  constructor(bonus: IUserBonus, extraLevels: number, maxLevels: number, duration: number) {
    super(bonus)
    this.extraLevels = extraLevels
    this.duration = duration
    this.maxLevels = maxLevels

    if (bonus.apiStatus === ApiBonusStatusEnums.Applied && !this.condition.isExpired) {
      this.status = BonusStatusEnums.Active
    }
  }
}

export interface IWageringCondition {
  minBet: number
  maxBet: number
  wagerMultiplier: number
  minDeposit?: string
  maxBonusAmountInUsd: string
  bonusAmount?: TokenAmount
  currentWager?: string
  targetWager?: string
  activeDurationInDays: number
  wagerDurationInDays: number
  gameCollectionId: number
}

export class WageringConditionBonus extends UserBonus implements IWageringCondition {
  readonly tags = [BonusTagEnums.CanActive]
  bonusAmount?: TokenAmount
  minBet: number
  maxBet: number
  wagerMultiplier: number
  minDeposit: string
  maxBonusAmountInUsd: string
  wageringBonusStatus: WageringBonusStatusEnums
  currentWager?: string
  targetWager?: string
  activeDurationInDays: number
  wagerDurationInDays: number
  gameCollectionId: number
  isActiveWagering?: boolean

  public get currentWagerBigAmount() {
    const currentWagerBigAmount = new BigNumber(this.currentWager)
    return currentWagerBigAmount.gt(this.targetWagerBigAmount) ? this.targetWagerBigAmount : currentWagerBigAmount
  }

  public get targetWagerBigAmount() {
    return new BigNumber(this.targetWager)
  }

  public get progress() {
    if (this.targetWagerBigAmount.eq(0)) return 100

    return Math.floor(this.currentWagerBigAmount.div(this.targetWagerBigAmount).multipliedBy(10000).toNumber()) / 100
  }

  public get minDepositBigAmount() {
    return new BigNumber(this.minDeposit)
  }

  public get maxBonusAmountInUsdBigAmount() {
    return new BigNumber(this.maxBonusAmountInUsd)
  }

  constructor(bonus: IUserBonus, wageringConditionValues: IWageringCondition) {
    super(bonus)

    this.minBet = wageringConditionValues.minBet
    this.maxBet = wageringConditionValues.maxBet
    this.wagerMultiplier = wageringConditionValues.wagerMultiplier
    this.minDeposit = wageringConditionValues.minDeposit
    this.maxBonusAmountInUsd = wageringConditionValues.maxBonusAmountInUsd
    this.bonusAmount = wageringConditionValues.bonusAmount
    this.currentWager = wageringConditionValues.currentWager
    this.targetWager = wageringConditionValues.targetWager
    this.activeDurationInDays = wageringConditionValues.activeDurationInDays
    this.wagerDurationInDays = wageringConditionValues.wagerDurationInDays
    this.gameCollectionId = wageringConditionValues.gameCollectionId
    this.isActiveWagering = false

    switch (bonus.apiStatus) {
      case ApiBonusStatusEnums.BetCollecting:
        this.wageringBonusStatus = WageringBonusStatusEnums.InProgressWagering
        this.isActiveWagering = true
        break

      case ApiBonusStatusEnums.WaitBetCollect:
        this.wageringBonusStatus = WageringBonusStatusEnums.InProgressWagering
        break

      case ApiBonusStatusEnums.WaitApply:
        this.wageringBonusStatus = WageringBonusStatusEnums.Available
        break

      case ApiBonusStatusEnums.WaitDeposit:
        this.wageringBonusStatus = WageringBonusStatusEnums.WaitingDeposit
        break

      case ApiBonusStatusEnums.WaitFRBResister:
        this.wageringBonusStatus = WageringBonusStatusEnums.Available
        break

      case ApiBonusStatusEnums.WaitClaim:
        this.wageringBonusStatus = WageringBonusStatusEnums.InProgressWagering
        this.isActiveWagering = true
        break

      case ApiBonusStatusEnums.WaitFRBPlay:
        this.wageringBonusStatus = WageringBonusStatusEnums.WaitingSpin
        break

      case ApiBonusStatusEnums.Applied:
        this.wageringBonusStatus = WageringBonusStatusEnums.Claimed
        break

      default:
        this.wageringBonusStatus = WageringBonusStatusEnums.Cancelled
        break
    }
  }
}

export class DepositBonus extends WageringConditionBonus {
  percent: number
  depositId?: number

  constructor(bonus: IUserBonus, wageringConditionValues: IWageringCondition, percent: number) {
    super(bonus, wageringConditionValues)
    this.percent = percent
    this.depositId = bonus.depositId

    if (bonus.apiStatus === ApiBonusStatusEnums.WaitDeposit) {
      this.status = BonusStatusEnums.Available
    }
  }
}

export class NoDepositBonus extends WageringConditionBonus {
  readonly tags = [BonusTagEnums.CanActive, BonusTagEnums.NoDeposit]

  constructor(bonus: IUserBonus, wageringConditionValues: IWageringCondition) {
    super(bonus, wageringConditionValues)

    if (bonus.apiStatus === ApiBonusStatusEnums.WaitAddBonusLock) {
      this.status = BonusStatusEnums.Available
    }
  }
}

export interface IFreespinBonus {
  freeSpinAmount: number
  spinPrice: number
  spinDurationInDays: number
  spinGameIds: number[]
}
export class FreespinBonus extends WageringConditionBonus implements IFreespinBonus {
  freeSpinAmount: number
  spinPrice: number
  spinDurationInDays: number
  spinGameIds: number[]

  constructor(bonus: IUserBonus, wageringConditionValues: IWageringCondition, freeSpinBonusValue: IFreespinBonus) {
    super(bonus, wageringConditionValues)
    this.freeSpinAmount = freeSpinBonusValue.freeSpinAmount
    this.spinPrice = freeSpinBonusValue.spinPrice
    this.spinDurationInDays = freeSpinBonusValue.spinDurationInDays
    this.spinGameIds = freeSpinBonusValue.spinGameIds

    if (bonus.apiStatus === ApiBonusStatusEnums.WaitDeposit) {
      this.status = BonusStatusEnums.Available
    }
  }
}

export class NoDepositFreespinBonus extends FreespinBonus {
  readonly tags = [BonusTagEnums.CanActive, BonusTagEnums.NoDeposit]

  public get bonusBigAmountInUsd() {
    return this.bonusAmount.token === HUSD_TOKEN ? new BigNumber(this.bonusAmount.amount) : BIG_ZERO
  }

  constructor(bonus: IUserBonus, wageringConditionValues: IWageringCondition, freeSpinBonusValue: IFreespinBonus) {
    super(bonus, wageringConditionValues, freeSpinBonusValue)

    if (bonus.apiStatus === ApiBonusStatusEnums.WaitFRBResister) {
      this.status = BonusStatusEnums.Available
    }

    if (bonus.apiStatus === ApiBonusStatusEnums.WaitDeposit) {
      this.status = BonusStatusEnums.Active
    }
  }
}

