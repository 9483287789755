import { createAction } from '@reduxjs/toolkit'
import { UserBonus } from 'config/types/bonus/userBonus'

export const updateActivatedBonuses = createAction<{ activatedBonuses: UserBonus[] }>('bonus/updateActivatedBonuses')
export const updateCurrentWagerInActivatedBonuses = createAction<{ currentWager: string; id: number }>(
  'bonus/updateCurrentWagerInActivatedBonuses',
)
export const updateBonusToActiveWagering = createAction<{ id: number }>('bonus/updateBonusToActiveWagering')

export const updateUserHUSDBonus = createAction<{
  unlockableBalance: string
  claimableBalance: string
  extraClaimableBalance?: string
}>('bonus/updateUserHUSDBonus')

export const updateUserClaimableHUSDAmount = createAction<{
  claimableBalance: string
  extraClaimableBalance?: string
}>('bonus/updateUserClaimableHUSDAmount')

export const updateUserUnlockableHUSDAmount = createAction<{
  unlockableBalance: string
}>('bonus/updateUserUnlockableHUSDAmount')

export const resetUserBonus = createAction('bonus/resetUserBonus')
