import { createSlice } from '@reduxjs/toolkit'
import { initSystemData } from './actions'

export interface SystemState {
  serviceShutdownAt: number
  serviceOpenAt: number
  isEnableMaintenanceMode: boolean
}

export const initialState: SystemState = {
  isEnableMaintenanceMode: false,
  serviceShutdownAt: null,
  serviceOpenAt: null,
}

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initSystemData, (state, { payload }) => {
      const { isEnableMaintenanceMode, serviceShutdownAt, serviceOpenAt } = payload

      state.isEnableMaintenanceMode = isEnableMaintenanceMode
      state.serviceShutdownAt = serviceShutdownAt
      state.serviceOpenAt = serviceOpenAt

      return state
    })
  },
})

export default systemSlice.reducer
