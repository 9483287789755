import { Network } from 'config/types'
import { clusterApiUrl } from '@solana/web3.js'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { zeroAddress } from 'viem'
import { Chain } from 'wagmi'

const path = '/images/network'

type ListNetworkByChainId = {
  [key: string]: Network
}

export enum ChainIdEnum {
  BNB = 56,
  BNB_TESTNET = 97,

  ETH = 1,
  ETH_TESTNET = 11155111,

  OEC = 66,
  OEC_TESTNET = 65,

  AVAX = 43114,
  AVAX_TESTNET = 43113,

  FTM = 250,
  FTM_TESTNET = 4002,

  MATIC = 137,
  MATIC_TESTNET = 80001,

  CELO = 42220,
  CELO_TESTNET = 44787,

  ONE = 1666600000,
  ONE_TESTNET = 1666700000,

  MOVR = 1285,
  MOVR_TESTNET = 1287,

  XDAI = 100,
  XDAI_TESTNET = 77,

  CRO = 25,
  CRO_TESTNET = 338,

  HECO = 128,
  HECO_TESTNET = 256,

  VLX = 106,
  VLX_TESTNET = 111,

  AOA = 1313161554,
  AOA_TESTNET = 1313161555,

  FUSE = 122,
  FUSE_TESTNET = 123,

  SOL = -1,
  SOL_TESTNET = -10,

  HPN = -2,
}

export const NETWORK_MAP: ListNetworkByChainId = {
  [ChainIdEnum.BNB]: {
    code: 'BSC',
    chainId: ChainIdEnum.BNB,
    rpcCollections: [
      'https://bsc-dataseed.binance.org/',
      'https://bsc-dataseed1.defibit.io/',
      'https://bsc-dataseed1.ninicoin.io/',
    ],
    blockExplorerUrls: 'https://bscscan.com',
    blockExplorerName: 'BSC Scan',
    networkInfo: {
      displayName: 'Binance Smart Chain',
      icon: `${path}/bsc.png`,
      shortName: 'BSC',
      native: {
        decimals: 18,
        name: 'BNB',
      },
    },
  },

  [ChainIdEnum.BNB_TESTNET]: {
    code: 'BSC_TESTNET',
    chainId: ChainIdEnum.BNB_TESTNET,
    rpcCollections: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: 'https://testnet.bscscan.com',
    blockExplorerName: 'BSC Scan',
    networkInfo: {
      displayName: 'Binance Smart Chain',
      icon: `${path}/bsc.png`,
      shortName: 'BSC',
      native: {
        decimals: 18,
        name: 'BNB',
      },
    },
  },

  [ChainIdEnum.ETH_TESTNET]: {
    code: 'ETH_TEST',
    chainId: ChainIdEnum.ETH_TESTNET,
    rpcCollections: ['https://sepolia.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: 'https://sepolia.etherscan.io/',
    blockExplorerName: 'ETH Goerli Scan',
    networkInfo: {
      displayName: 'Ethereum',
      icon: `${path}/eth.png`,
      shortName: 'ETH',
      native: {
        decimals: 18,
        name: 'ETH',
      },
    },
  },
  [ChainIdEnum.ETH]: {
    code: 'ETH',
    chainId: ChainIdEnum.ETH,
    rpcCollections: ['https://rpc.ankr.com/eth'],
    blockExplorerUrls: 'https://etherscan.io/',
    blockExplorerName: 'Etherscan',
    networkInfo: {
      displayName: 'Ethereum',
      icon: `${path}/eth.png`,
      shortName: 'ETH',
      native: {
        decimals: 18,
        name: 'ETH',
      },
    },
  },
  [ChainIdEnum.AVAX]: {
    code: 'AVAX',
    chainId: ChainIdEnum.AVAX,
    rpcCollections: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: 'https://snowtrace.io',
    blockExplorerName: 'Snowtrace',
    networkInfo: {
      displayName: 'Avalanche C Chain',
      icon: `${path}/avax.png`,
      shortName: 'Avalanche',
      native: {
        decimals: 18,
        name: 'AVAX',
      },
    },
  },

  [ChainIdEnum.AVAX_TESTNET]: {
    code: 'AVAX-FUJI',
    chainId: ChainIdEnum.AVAX_TESTNET,
    rpcCollections: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: 'https://testnet.snowtrace.io',
    blockExplorerName: 'Snowtrace (Testnet)',
    networkInfo: {
      displayName: 'Avalanche Juji Chain',
      icon: `${path}/avax.png`,
      shortName: 'Avalanche',
      native: {
        decimals: 18,
        name: 'AVAX',
      },
    },
  },
  [ChainIdEnum.OEC]: {
    code: 'OKEx',
    chainId: ChainIdEnum.OEC,
    rpcCollections: ['https://exchainrpc.okex.org/'],
    blockExplorerUrls: 'https://www.oklink.com/oec',
    blockExplorerName: 'OKLink',
    networkInfo: {
      displayName: 'OKEx Chain',
      icon: `${path}/okt.png`,
      shortName: 'OKEx',
      native: {
        decimals: 18,
        name: 'OKT',
      },
    },
  },
  [ChainIdEnum.OEC_TESTNET]: {
    code: 'OKEx',
    chainId: ChainIdEnum.OEC_TESTNET,
    rpcCollections: ['https://exchaintestrpc.okex.org'],
    blockExplorerUrls: 'https://www.oklink.com/oec-test',
    blockExplorerName: 'OKLink',
    networkInfo: {
      displayName: 'OKEx Chain',
      icon: `${path}/okt.png`,
      shortName: 'OKEx',
      native: {
        decimals: 18,
        name: 'OKT',
      },
    },
  },
  [ChainIdEnum.FTM]: {
    code: 'FTM',
    chainId: ChainIdEnum.FTM,
    rpcCollections: ['https://rpc.ftm.tools'],
    blockExplorerUrls: 'https://ftmscan.com',
    blockExplorerName: 'FTM scan',
    networkInfo: {
      displayName: 'Fantom',
      icon: `${path}/ftm.png`,
      shortName: 'FTM',
      native: {
        decimals: 18,
        name: 'OKT',
      },
    },
  },
  [ChainIdEnum.FTM_TESTNET]: {
    code: 'FTM_TESTNET',
    chainId: ChainIdEnum.FTM_TESTNET,
    rpcCollections: ['https://xapi.testnet.fantom.network/lachesis/'],
    blockExplorerUrls: 'https://testnet.ftmscan.com',
    blockExplorerName: 'FTM scan',
    networkInfo: {
      displayName: 'Fantom',
      icon: `${path}/ftm.png`,
      shortName: 'FTM',
      native: {
        decimals: 18,
        name: 'FTM',
      },
    },
  },
  [ChainIdEnum.MATIC]: {
    code: 'MATIC',
    chainId: ChainIdEnum.MATIC,
    rpcCollections: ['https://polygon-rpc.com/'],
    blockExplorerUrls: 'https://polygonscan.com',
    blockExplorerName: 'Polygon scan',
    networkInfo: {
      displayName: 'Polygon',
      icon: `${path}/matic.png`,
      shortName: 'POLYGON',
      native: {
        decimals: 18,
        name: 'MATIC',
      },
    },
  },
  [ChainIdEnum.MATIC_TESTNET]: {
    code: 'MATIC_TESTNET',
    chainId: ChainIdEnum.MATIC_TESTNET,
    rpcCollections: ['https://rpc-mumbai.maticvigil.com/'],
    blockExplorerUrls: 'https://mumbai.polygonscan.com',
    blockExplorerName: 'Polygon scan',
    networkInfo: {
      displayName: 'Polygon',
      icon: `${path}/matic.png`,
      shortName: 'POLYGON',
      native: {
        decimals: 18,
        name: 'MATIC',
      },
    },
  },
  [ChainIdEnum.CELO]: {
    code: 'CELO',
    chainId: ChainIdEnum.CELO,
    rpcCollections: ['https://forno.celo.org/'],
    blockExplorerUrls: 'https://forno.celo.org/',
    blockExplorerName: 'Celo scan',
    networkInfo: {
      displayName: 'Celo',
      icon: `${path}/celo.png`,
      shortName: 'CELO',
      native: {
        decimals: 18,
        name: 'CELO',
      },
    },
  },
  [ChainIdEnum.CELO_TESTNET]: {
    code: 'CELO_TESTNET',
    chainId: ChainIdEnum.CELO_TESTNET,
    rpcCollections: ['https://alfajores-forno.celo-testnet.org/'],
    blockExplorerUrls: 'https://alfajores-blockscout.celo-testnet.org',
    blockExplorerName: 'CELO scan',
    networkInfo: {
      displayName: 'Celo',
      icon: `${path}/celo.png`,
      shortName: 'CELO',
      native: {
        decimals: 18,
        name: 'CELO',
      },
    },
  },
  [ChainIdEnum.ONE]: {
    code: 'ONE',
    chainId: ChainIdEnum.ONE,
    rpcCollections: ['https://api.harmony.one/'],
    blockExplorerUrls: 'https://explorer.harmony.one',
    blockExplorerName: 'Harmony scan',
    networkInfo: {
      displayName: 'Harmony',
      icon: `${path}/one.png`,
      shortName: 'Harmony',
      native: {
        decimals: 18,
        name: 'ONE',
      },
    },
  },
  [ChainIdEnum.ONE_TESTNET]: {
    code: 'ONE',
    chainId: ChainIdEnum.ONE_TESTNET,
    rpcCollections: ['https://api.s0.b.hmny.io/'],
    blockExplorerUrls: 'https://explorer.pops.one',
    blockExplorerName: 'Harmony scan',
    networkInfo: {
      displayName: 'Harmony',
      icon: `${path}/one.png`,
      shortName: 'Harmony',
      native: {
        decimals: 18,
        name: 'ONE',
      },
    },
  },
  [ChainIdEnum.MOVR]: {
    code: 'MOVR',
    chainId: ChainIdEnum.MOVR,
    rpcCollections: ['https://rpc.moonriver.moonbeam.network/'],
    blockExplorerUrls: 'https://moonriver.moonscan.io',
    blockExplorerName: 'MoonRiver scan',
    networkInfo: {
      displayName: 'MoonRiver',
      icon: `${path}/movr.png`,
      shortName: 'MoonRiver',
      native: {
        decimals: 18,
        name: 'MOVR',
      },
    },
  },
  [ChainIdEnum.MOVR_TESTNET]: {
    code: 'MOVR_TESTNET',
    chainId: ChainIdEnum.MOVR_TESTNET,
    rpcCollections: ['https://rpc.testnet.moonbeam.network/'],
    blockExplorerUrls: 'https://moonriver.moonscan.io',
    blockExplorerName: 'MoonRiver scan',
    networkInfo: {
      displayName: 'MoonRiver',
      icon: `${path}/movr.png`,
      shortName: 'MoonRiver',
      native: {
        decimals: 18,
        name: 'MOVR',
      },
    },
  },
  [ChainIdEnum.XDAI]: {
    code: 'XDAI',
    chainId: ChainIdEnum.XDAI,
    rpcCollections: ['https://rpc.xdaichain.com/'],
    blockExplorerUrls: 'https://blockscout.com/xdai/mainnet',
    blockExplorerName: 'xDai scan',
    networkInfo: {
      displayName: 'xDai',
      icon: `${path}/xdai.png`,
      shortName: 'xDai',
      native: {
        decimals: 18,
        name: 'xDai',
      },
    },
  },
  [ChainIdEnum.XDAI_TESTNET]: {
    code: 'XDAI_TESTNET',
    chainId: ChainIdEnum.XDAI_TESTNET,
    rpcCollections: ['https://sokol.poa.network/'],
    blockExplorerUrls: 'https://blockscout.com/poa/sokol',
    blockExplorerName: 'xDai scan',
    networkInfo: {
      displayName: 'xDai',
      icon: `${path}/xdai.png`,
      shortName: 'xDai',
      native: {
        decimals: 18,
        name: 'xDai',
      },
    },
  },
  [ChainIdEnum.CRO]: {
    code: 'CRO',
    chainId: ChainIdEnum.CRO,
    rpcCollections: ['https://evm-cronos.crypto.org/'],
    blockExplorerUrls: 'https://cronos.crypto.org/explorer',
    blockExplorerName: 'Cronos scan',
    networkInfo: {
      displayName: 'Cronos',
      icon: `${path}/cro.png`,
      shortName: 'Cronos',
      native: {
        decimals: 18,
        name: 'CRO',
      },
    },
  },
  [ChainIdEnum.CRO_TESTNET]: {
    code: 'CRO_TESTNET',
    chainId: ChainIdEnum.CRO_TESTNET,
    rpcCollections: ['https://cronos-testnet-3.crypto.org:8545/'],
    blockExplorerUrls: 'https://cronos.crypto.org/explorer/testnet3',
    blockExplorerName: 'Cronos scan',
    networkInfo: {
      displayName: 'Cronos',
      icon: `${path}/cro.png`,
      shortName: 'Cronos',
      native: {
        decimals: 18,
        name: 'CRO',
      },
    },
  },
  [ChainIdEnum.HECO]: {
    code: 'HECO',
    chainId: ChainIdEnum.HECO,
    rpcCollections: ['https://http-mainnet.hecochain.com/'],
    blockExplorerUrls: 'https://hecoinfo.com',
    blockExplorerName: 'Houbi scan',
    networkInfo: {
      displayName: 'Houbi ECO Chain',
      icon: `${path}/ht.png`,
      shortName: 'HECO',
      native: {
        decimals: 18,
        name: 'HT',
      },
    },
  },
  [ChainIdEnum.HECO_TESTNET]: {
    code: 'HECO_TESTNET',
    chainId: ChainIdEnum.HECO_TESTNET,
    rpcCollections: ['https://http-testnet.hecochain.com/'],
    blockExplorerUrls: 'https://testnet.hecoinfo.com',
    blockExplorerName: 'Houbi scan',
    networkInfo: {
      displayName: 'Houbi ECO Chain',
      icon: `${path}/ht.png`,
      shortName: 'HECO',
      native: {
        decimals: 18,
        name: 'HT',
      },
    },
  },
  [ChainIdEnum.VLX]: {
    code: 'VLX',
    chainId: ChainIdEnum.VLX,
    rpcCollections: ['https://evmexplorer.velas.com/rpc/'],
    blockExplorerUrls: 'https://evmexplorer.velas.com',
    blockExplorerName: 'Velas scan',
    networkInfo: {
      displayName: 'Velas',
      icon: `${path}/vlx.png`,
      shortName: 'Velas',
      native: {
        decimals: 18,
        name: 'HT',
      },
    },
  },
  [ChainIdEnum.VLX_TESTNET]: {
    code: 'VLX_TESTNET',
    chainId: ChainIdEnum.VLX_TESTNET,
    rpcCollections: ['https://testnet.velas.com/rpc/'],
    blockExplorerUrls: 'https://evmexplorer.testnet.velas.com',
    blockExplorerName: 'Velas scan',
    networkInfo: {
      displayName: 'Velas',
      icon: `${path}/vlx.png`,
      shortName: 'Velas',
      native: {
        decimals: 18,
        name: 'VLX',
      },
    },
  },
  [ChainIdEnum.AOA]: {
    code: 'AOA',
    chainId: ChainIdEnum.AOA,
    rpcCollections: ['https://mainnet.aurora.dev/'],
    blockExplorerUrls: 'https://explorer.mainnet.aurora.dev',
    blockExplorerName: 'Aurora scan',
    networkInfo: {
      displayName: 'Aurora',
      icon: `${path}/aurora.png`,
      shortName: 'Aurora',
      native: {
        decimals: 18,
        name: 'ETH',
      },
    },
  },
  [ChainIdEnum.AOA_TESTNET]: {
    code: 'AOA_TESTNET',
    chainId: ChainIdEnum.AOA_TESTNET,
    rpcCollections: ['https://testnet.aurora.dev/'],
    blockExplorerUrls: 'https://explorer.testnet.aurora.dev',
    blockExplorerName: 'Aurora scan',
    networkInfo: {
      displayName: 'Aurora',
      icon: `${path}/aurora.png`,
      shortName: 'Aurora',
      native: {
        decimals: 18,
        name: 'ETH',
      },
    },
  },
  [ChainIdEnum.FUSE]: {
    code: 'FUSE',
    chainId: ChainIdEnum.FUSE,
    rpcCollections: ['https://rpc.fuse.io/'],
    blockExplorerUrls: 'https://explorer.fuse.io',
    blockExplorerName: 'Fuse scan',
    networkInfo: {
      displayName: 'Fuse',
      icon: `${path}/fuse.png`,
      shortName: 'Fuse',
      native: {
        decimals: 18,
        name: 'FUSE',
      },
    },
  },
  [ChainIdEnum.FUSE_TESTNET]: {
    code: 'FUSE_TESTNET',
    chainId: ChainIdEnum.FUSE_TESTNET,
    rpcCollections: ['https://rpc.fusespark.io/'],
    blockExplorerUrls: 'https://explorer.fusespark.io',
    blockExplorerName: 'Fuse scan',
    networkInfo: {
      displayName: 'Fuse',
      icon: `${path}/fuse.png`,
      shortName: 'Fuse',
      native: {
        decimals: 18,
        name: 'FUSE',
      },
    },
  },
  [ChainIdEnum.SOL]: {
    code: 'SOL',
    chainId: -1,
    rpcCollections: ['https://solana-mainnet.g.alchemy.com/v2/JH7TEGB_ecM-SpBFtuimdmH2MrfC5-Tg'],
    blockExplorerUrls: 'https://solscan.io',
    blockExplorerName: 'SolScan',
    networkInfo: {
      displayName: 'SOLANA',
      icon: `${path}/sol.png`,
      shortName: 'Solana',
      native: {
        decimals: 9,
        name: 'SOL',
      },
    },
  },
  [ChainIdEnum.SOL_TESTNET]: {
    code: 'SOL_TESTNET',
    chainId: -10,
    rpcCollections: [clusterApiUrl(WalletAdapterNetwork.Testnet)],
    blockExplorerUrls: 'https://solscan.io',
    blockExplorerName: 'SolScan',
    networkInfo: {
      displayName: 'SOLANA',
      icon: `${path}/sol.png`,
      shortName: 'Solana',
      native: {
        decimals: 9,
        name: 'SOL',
      },
    },
  },
}

export const getNetworkInitForWagami = () => {
  return Object.values(NETWORK_MAP)
    .map((network) => {
      if (network.chainId < 0) return null

      return {
        id: network.chainId,
        name: network.networkInfo.displayName,
        network: network.networkInfo.displayName.toLowerCase(),
        nativeCurrency: {
          name: network.networkInfo.native.name,
          symbol: network.networkInfo.native.name,
          decimals: network.networkInfo.native.decimals,
        },
        rpcUrls: {
          public: {
            http: network.rpcCollections,
          },
          default: {
            http: network.rpcCollections,
          },
        },
        blockExplorers: {
          default: {
            name: network.blockExplorerName,
            url: network.blockExplorerUrls,
          },
        },
        contracts: {
          multicall3: {
            address: zeroAddress,
            blockCreated: 0,
          },
        },
      } as Chain
    })
    .filter((item) => item)
}
