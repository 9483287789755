import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  XDAI: {
    coinGeckoInfo: {
      id: 'xdai',
    },
    logo: `${path}/xdai.png`,
    name: 'XDAI',
    code: 'XDAI',
    network: ChainIdEnum.XDAI_TESTNET,
    isNative: true,
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.XDAI_TESTNET,
    address: '0x61353e9ad11500b3180a82d030f6CB3a250262E8',
    decimals: 6,
    isStable: true,
  },
}

export default tokens
