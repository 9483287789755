import { RouteConfig } from './constants/route'
import {
  DEFAULT_META_DESCRIPTION,
  DEFAULT_META_OG_IMAGE,
  DEFAULT_META_TITLE,
  PRODUCT_NAME,
} from './constants/variables'
import { MetaTag } from './types/metatag'

export const getDefaultMeta = (t: (text: string) => string = (text) => text): MetaTag => {
  return {
    title: t(DEFAULT_META_TITLE),
    description: t(DEFAULT_META_DESCRIPTION),
    ogImage: DEFAULT_META_OG_IMAGE,
  }
}

export const DEFAULT_META: MetaTag = getDefaultMeta()

export const getCustomMeta = (path: RouteConfig, t: (text: string) => string = (text) => text): MetaTag => {
  if (path.startsWith(RouteConfig.Blogs)) {
    return {
      ...getDefaultMeta(t),
      title: `${t('News')} | ${t(PRODUCT_NAME)}`,
    }
  }
  if (path.startsWith(RouteConfig.BonusCenter)) {
    return {
      ...getDefaultMeta(t),
      title: `${t('Bonus center')} | ${t(PRODUCT_NAME)}`,
    }
  }
  if (path.startsWith(RouteConfig.Promotions)) {
    return {
      ...getDefaultMeta(t),
      title: `${t('Promotions')} | ${t(PRODUCT_NAME)}`,
    }
  }
  if (path.startsWith(RouteConfig.Games)) {
    return {
      ...getDefaultMeta(t),
      title: `${t('Games')} | ${t(PRODUCT_NAME)}`,
    }
  }
  if (path.startsWith(RouteConfig.Cashback)) {
    return {
      ...getDefaultMeta(t),
      title: `${t('Cashback')} | ${t(PRODUCT_NAME)}`,
    }
  }
  if (path.startsWith(RouteConfig.CashbackAbout)) {
    return {
      ...getDefaultMeta(t),
      title: `${t('Cashback information')} | ${t(PRODUCT_NAME)}`,
    }
  }
  if (path.startsWith(RouteConfig.QueryDeposit)) {
    return {
      ...getDefaultMeta(t),
      title: `${t('Query deposit')} | ${t(PRODUCT_NAME)}`,
    }
  }
  if (path.startsWith(RouteConfig.Sport)) {
    return {
      ...getDefaultMeta(t),
      title: `${t('Sport')} | ${t(PRODUCT_NAME)}`,
    }
  }
  if (path.startsWith(RouteConfig.Profile)) {
    return {
      ...getDefaultMeta(t),
      title: `${t('Profile')} | ${t(PRODUCT_NAME)}`,
    }
  }
  if (path.startsWith(RouteConfig.Stake)) {
    return {
      ...getDefaultMeta(t),
      title: `${t('Staking')} | ${t(PRODUCT_NAME)}`,
    }
  }
  if (path.startsWith(RouteConfig.Referral)) {
    return {
      ...getDefaultMeta(t),
      title: `${t('Referral')} | ${t(PRODUCT_NAME)}`,
    }
  }
  if (path.startsWith(RouteConfig.HunnyJar)) {
    return {
      ...getDefaultMeta(t),
      title: `${t('Hunny Jar')} | ${t(PRODUCT_NAME)}`,
    }
  }
  if (path.startsWith(RouteConfig.TransactionHistory)) {
    return {
      ...getDefaultMeta(t),
      title: `${t('Transactions')} | ${t(PRODUCT_NAME)}`,
    }
  }
  return {
    ...getDefaultMeta(t),
  }
}

