import { Colors } from './types'

export const colors: Colors = {
  primary: '#E960AF',
  secondary: '#F3BA2F',
  primaryDisabled: 'rgb(92, 47, 75)',

  normal: '#272f52',
  success: '#06C270',
  info: '#49a1b8',
  error: '#ED6A5E',
  warning: '#F3BF4F',

  background: '#16191f',
  backgroundAlt: '#191d25',
  backgroundAlt2: '#2b2f36',
  backgroundAlt3: '#22293d',
  backgroundAlt4: '#212947',
  backgroundAlt5: 'rgba(255, 255, 255, 0.04)',
  backgroundAlt6: 'rgb(22, 28, 36)',
  backgroundAlt7: 'rgba(35, 44, 61, 0.2)',
  providerBackground: 'rgba(35, 44, 61, 0.6)',
  backgroundCashBack: 'rgba(22, 25, 31, 1)',

  backdrop: 'rgba(0, 0, 0, 0.7)',
  // Gradient
  gradient: {
    primary: 'linear-gradient(271.18deg, rgb(243, 198, 34) -54.06%, rgb(249, 83, 198) 83.78%)',
    secondary: 'linear-gradient(270deg, #e960af -10.61%, #f3c622 112.88%)',
    tertiary: 'linear-gradient(126.03deg, rgb(249, 83, 198) 19.96%, rgb(243, 198, 34) 167.68%)',
  },

  // Text
  text: '#fff',
  textDisable: '#484F5D',
  textSubtle: '#ADADAD',
  textTertiary: '#E0E0E0',
  textHighlight: '#F3C622',
  textAlt: 'rgb(170, 149, 133)',
  textAlt1: '#6C727E',

  // Stroke
  stroke: '#272f52',
  strokeAlt: '#5769B8',
  strokeAlt2: 'rgba(249, 83, 198, 0.37)',
  strokeAlt1: 'rgb(39, 47, 82)',
  strokeAlt3: '#1877F2',
  strokeBlur: 'rgba(255, 255, 255, 0.16)',
  // Modal
  modalBackground: '#16191F',
  modalHeaderBackground: '#1D2533',

  // Button
  buttonBackground: 'linear-gradient(271.18deg, rgb(243, 198, 34) -54.06%, rgb(249, 83, 198) 83.78%)',
  buttonBackgroundAlt: 'rgb(233, 96, 175)',
  buttonBackgroundAlt2: '#232C3D',

  // Card
  cardBackground: '#161c24',

  // Input
  inputDefault: '#151B25',
  inputAlt: '#1B212E',
  inputPlaceHolder: '#7d7f85',
  inputBackground: '#191D25',
  inputBackgroundAlt: 'rgb(30, 18, 18)',
  inputBlur: 'rgba(35, 44, 61, 0.4)',
}
