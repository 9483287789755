import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  CRO: {
    coinGeckoInfo: {
      id: 'crypto-com-chain',
    },
    logo: `${path}/cro.png`,
    name: 'CRO',
    code: 'CRO',
    network: ChainIdEnum.CRO_TESTNET,
    isNative: true,
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.CRO_TESTNET,
    address: '0x2A3885B3F0C98F3E36334d4FA7BEDA53Cb0ae095',
    decimals: 6,
    isStable: true,
  },
}

export default tokens
