import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  ONE: {
    coinGeckoInfo: {
      id: 'harmony',
    },
    logo: `${path}/one.png`,
    name: 'ONE',
    code: 'ONE',
    network: ChainIdEnum.ONE,
    isNative: true,
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.ONE,
    address: '0x3c2b8be99c50593081eaa2a724f0b8285f5aba8f',
    decimals: 6,
    isStable: true,
  },
}

export default tokens
