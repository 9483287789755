import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  AVAX: {
    coinGeckoInfo: {
      id: 'avalanche-2',
    },
    logo: `${path}/avax.png`,
    name: 'AVAX',
    code: 'AVAX',
    network: ChainIdEnum.AVAX_TESTNET,
    isNative: true,
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.AVAX_TESTNET,
    address: '0xD61134e78D7772b9F6e0E621AED4bEf5C8386B82',
    decimals: 18,
    isStable: true,
  },
}

export default tokens
