import { createSlice } from '@reduxjs/toolkit'
import { RefToken } from 'config/types'
import { onlyUnique } from 'utils'
import {
  addSearchHistoryText,
  clearSearchHistory,
  removeSearchHistoryText,
  selectPlayBalance,
  updateDeviceUid,
} from './actions'

export interface SessionState {
  deviceUid: string
  selectToken: RefToken
  searchHistory: string[]
}

export const initialState: SessionState = {
  deviceUid: '',
  selectToken: null,
  searchHistory: [],
}

export const appSlice = createSlice({
  name: 'Session',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(selectPlayBalance, (state, { payload }) => {
        const { token } = payload

        state.selectToken = {
          token: token.name,
          network: token.network,
        }

        return state
      })
      .addCase(updateDeviceUid, (state, { payload }) => {
        const { deviceUid } = payload

        state.deviceUid = deviceUid

        return state
      })
      .addCase(addSearchHistoryText, (state, { payload }) => {
        state.searchHistory = [payload.text, ...(state.searchHistory || [])].filter(onlyUnique)
        return state
      })
      .addCase(removeSearchHistoryText, (state, { payload }) => {
        state.searchHistory = state.searchHistory.filter((text) => text !== payload.text)
        return state
      })
      .addCase(clearSearchHistory, (state) => {
        state.searchHistory = []
        return state
      })
  },
})

export default appSlice.reducer
