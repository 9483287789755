import { createAction } from '@reduxjs/toolkit'
import { CashbackTransaction } from 'config/types/cashback'

export const updateAvailableCashbacks = createAction<{ availableCashbacks: CashbackTransaction[] }>(
  'cashback/updateAvailableCashbacks',
)
export const updateTotalEarnedCashbacks = createAction<{ totalEarnedCashbacks: CashbackTransaction[] }>(
  'cashback/updateTotalEarnedCashbacks',
)
export const updateNextCashbackTime = createAction<{ nextCashbackTime: number }>('cashback/updateNextCashbackTime')
