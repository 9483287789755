import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  FTM: {
    coinGeckoInfo: {
      id: 'fantom',
    },
    logo: `${path}/ftm.png`,
    name: 'FTM',
    code: 'FTM',
    network: ChainIdEnum.FTM_TESTNET,
    isNative: true,
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.FTM_TESTNET,
    address: '0x2A3885B3F0C98F3E36334d4FA7BEDA53Cb0ae095',
    decimals: 18,
    isStable: true,
  },
  USDC: {
    coinGeckoInfo: {
      id: 'usd-coin',
    },
    logo: `${path}/usdc.png`,
    name: 'USDC',
    code: 'USDC',
    network: ChainIdEnum.FTM_TESTNET,
    address: '0x3aD2a2249EB33e39961675C9f27cC28Ff70DC4f0',
    decimals: 6,
    isStable: true,
  },
  BOO: {
    coinGeckoInfo: {
      id: 'spookyswap',
    },
    logo: `${path}/boo.png`,
    name: 'BOO',
    code: 'BOO',
    network: ChainIdEnum.FTM_TESTNET,
    address: '0xE18492c7CBD2b58e2AcB46Ab869aCEb3a4273FA1',
    decimals: 18,
  },
}

export default tokens
