import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  OKT: {
    coinGeckoInfo: {
      id: 'oec-token',
    },
    logo: `${path}/okt.png`,
    name: 'OKT',
    code: 'OKT',
    network: ChainIdEnum.OEC_TESTNET,
    isNative: true,
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.OEC_TESTNET,
    address: '0x536ae5308db81aefc83470424e41c6b9b6a668b8',
    decimals: 18,
    isStable: true,
  },
}

export default tokens
