import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  MOVR: {
    coinGeckoInfo: {
      id: 'moonriver',
    },
    logo: `${path}/movr.png`,
    name: 'MOVR',
    code: 'MOVR',
    network: ChainIdEnum.MOVR,
    isNative: true,
    decimals: 18,
  },
}

export default tokens
