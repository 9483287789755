import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  MATIC: {
    coinGeckoInfo: {
      id: 'matic-network',
    },
    logo: `${path}/matic.png`,
    name: 'MATIC',
    code: 'MATIC',
    network: ChainIdEnum.MATIC,
    isNative: true,
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.MATIC,
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    decimals: 6,
    isStable: true,
  },
}

export default tokens
