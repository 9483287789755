import { createSlice } from '@reduxjs/toolkit'
import { CashbackTransaction } from 'config/types/cashback'
import { updateAvailableCashbacks, updateNextCashbackTime, updateTotalEarnedCashbacks } from './actions'

export interface CashbackState {
  availableCashbacks: CashbackTransaction[]
  totalEarnedCashbacks: CashbackTransaction[]
  nextCashbackTime: number
}

export const initialCashbackState: CashbackState = {
  availableCashbacks: [],
  totalEarnedCashbacks: [],
  nextCashbackTime: 0,
}

export const cashbackSlice = createSlice({
  name: 'Cashback',
  initialState: initialCashbackState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateAvailableCashbacks, (state, { payload }) => {
      const { availableCashbacks } = payload

      state.availableCashbacks = availableCashbacks
      return state
    })
    builder.addCase(updateTotalEarnedCashbacks, (state, { payload }) => {
      const { totalEarnedCashbacks } = payload

      state.totalEarnedCashbacks = totalEarnedCashbacks
      return state
    })
    builder.addCase(updateNextCashbackTime, (state, { payload }) => {
      const { nextCashbackTime } = payload

      state.nextCashbackTime = nextCashbackTime
    })
  },
})

export default cashbackSlice.reducer
