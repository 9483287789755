export enum RouteConfig {
  Home = '/',
  Games = '/games',
  GamesTag = '/games/{{tag}}',
  GameDetail = '/{{slug}}',
  Promotions = '/promotions',
  PromotionDetail = '/promotions/{{promotionSlug}}',
  ProviderDetail = '/providers/{{code}}',
  Sport = '/sport',
  Spin = '/spin',
  SpinToWin = '/spin-to-win',
  QueryDeposit = '/query/deposit',
  Profile = '/profile',
  Stake = '/staking',
  Referral = '/referral',
  HunnyJar = '/',
  Cashback = '/cashback',
  CashbackAbout = '/cashback/about',
  Blogs = '/news',
  TagDetails = '/news/tag/{{slug}}',
  PostDetails = '/news/{{slug}}',
  Maintenance = '/maintenance',
  BonusCenter = '/bonuses',
  Restriction = '/restriction',

  Account = '/profile/account',
  FavoriteGames = '/profile/favorite-games',
  TransactionHistory = '/profile/transactions',
  MyRewards = '/profile/my-rewards',
  RedeemBonus = '/redeem/bonus/{{code}}',

  HunnyPoker = 'https://hunnypoker.com/',
  HunnyFarm = 'https://hunny.finance/',
  HunnySwap = 'https://hunnyswap.com/',
  HunnyBlogFinance = 'http://blog.hunny.finance/',
  HunnyDocs = 'https://docs.hunny.finance/products/hunnyplay',
  TermOfService = 'https://docs.hunny.finance/products/hunnyplay/hunnyplay-terms-and-conditions',

  Twitter = 'https://twitter.com/HunnyPlay_',
  Telegram = 'https://t.me/HunnyPlay',
  Medium = 'https://medium.com/hunnyfinance',
  Discord = 'https://discord.com/invite/hunnyplay',
  Reddit = 'https://www.reddit.com/r/HunnyPlay_/',
  Facebook = 'https://www.facebook.com/HunnyPlay',
  Instagram = 'https://www.instagram.com/HunnyPlay_/',
  Youtube = 'https://www.youtube.com/@hunnyplay',
}

