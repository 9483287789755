import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  OKT: {
    coinGeckoInfo: {
      id: 'oec-token',
    },
    logo: `${path}/okt.png`,
    name: 'OKT',
    code: 'OKT',
    network: ChainIdEnum.OEC,
    isNative: true,
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.OEC,
    address: '0x382bb369d343125bfb2117af9c149795c6c65c50',
    decimals: 18,
    isStable: true,
  },
}

export default tokens
