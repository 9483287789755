import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  CRO: {
    coinGeckoInfo: {
      id: 'crypto-com-chain',
    },
    logo: `${path}/cro.png`,
    name: 'CRO',
    code: 'CRO',
    network: ChainIdEnum.CRO,
    isNative: true,
    decimals: 18,
  },
}

export default tokens
