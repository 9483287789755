import { createAction } from '@reduxjs/toolkit'

export const addPendingDepositTransaction = createAction<{ hash: string }>('transaction/addPendingDepositTransaction')

export const removePendingDepositTransaction = createAction<{ hash: string }>(
  'transaction/removePendingDepositTransaction',
)

export const addPendingWithdrawTransaction = createAction<{ code: string }>('transaction/addPendingWithdrawTransaction')

export const removePendingWithdrawTransaction = createAction<{ code: string }>(
  'transaction/removePendingWithdrawTransaction',
)

export const clearPendingTransaction = createAction('transaction/clearPendingTransaction')
