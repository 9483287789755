import { createSlice } from '@reduxjs/toolkit'
import { LoginMethod } from 'config/constants/auth'
import { WalletType } from 'config/types/wallet'
import { login, logout, removeSuggestedLoginInfo } from './action'

export interface AuthState {
  loginBy: LoginMethod
  username: string
  uid: number
  wallet?: {
    type: WalletType
    connectorId: string
    name: string

    address?: string
    trackingName?: string
  }
  email: string
}

export const initialState: AuthState = {
  loginBy: null,
  username: null,
  uid: null,
  wallet: null,
  email: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login, (state, { payload }) => {
        const { loginBy, username, uid, wallet, email } = payload.payload

        state = {
          loginBy,
          username,
          uid,
          wallet,
          email,
        }

        return state
      })
      .addCase(logout, (state) => {
        state = {
          ...initialState,
          email: state.email,
          loginBy: state.loginBy,
        }

        return state
      })
      .addCase(removeSuggestedLoginInfo, (state) => {
        state.email = null
        state.loginBy = null
        return state
      })
  },
})

export default authSlice.reducer
