import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  CELO: {
    coinGeckoInfo: {
      id: 'celo',
    },
    logo: `${path}/celo.png`,
    name: 'CELO',
    code: 'CELO',
    network: ChainIdEnum.CELO,
    isNative: true,
    decimals: 18,
  },
}

export default tokens
