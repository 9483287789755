import { createAction } from '@reduxjs/toolkit'
import { MyTierInfo, WagerInfo } from 'config/types/profile'
import { BalanceResponse, UserData } from 'services/types'

export const initialProfile = createAction<{ data: UserData }>('profile/initialProfile')
export const updateBalances = createAction<{ data: BalanceResponse[] }>('profile/updateBalances')

export const updateMyTier = createAction<{ data: MyTierInfo }>('profile/updateMyTier')
export const updateMyGameLevelStats = createAction<{ data: WagerInfo }>('profile/upateMyGameLevelStats')
export const updateUserFreeLuckySpin = createAction<{ nextSpinTime: number; amount: number }>(
  'profile/updateUserFreeLuckySpin',
)
export const updateUserWelcomePackageInfo = createAction<{ expiredAt: number; isClaimed: boolean }>(
  'profile/updateUserWelcomePackageInfo',
)
export const updateFavoriteGames = createAction<{ favoriteGames: Record<string, boolean> }>(
  'profile/updateFavoriteGames',
)
export const updateFavoriteGame = createAction<{ gameCode: string; isFavorited: boolean }>('profile/updateFavoriteGame')
export const updateEligibleWageringBonusGames = createAction<{
  eligibleWageringBonusGames: Record<string, boolean>
}>('profile/updateEligibleWageringBonusGames')

export const resetProfile = createAction<{ data: WagerInfo }>('profile/resetProfile')
