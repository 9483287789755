import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  ETH: {
    coinGeckoInfo: {
      id: 'ethereum',
    },
    logo: `${path}/eth.png`,
    name: 'ETH',
    code: 'ETH',
    network: ChainIdEnum.AOA_TESTNET,
    isNative: true,
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.AOA_TESTNET,
    address: '0x61353e9ad11500b3180a82d030f6CB3a250262E8',
    decimals: 6,
    isStable: true,
  },
}

export default tokens
