import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  HT: {
    coinGeckoInfo: {
      id: 'huobi-token',
    },
    logo: `${path}/ht.png`,
    name: 'HT',
    code: 'HT',
    network: ChainIdEnum.HECO,
    isNative: true,
    decimals: 18,
  },
}

export default tokens
