import { createSlice } from '@reduxjs/toolkit'
import { UserBonus, WageringConditionBonus } from 'config/types/bonus/userBonus'
import {
  resetUserBonus,
  updateActivatedBonuses,
  updateBonusToActiveWagering,
  updateCurrentWagerInActivatedBonuses,
  updateUserClaimableHUSDAmount,
  updateUserHUSDBonus,
  updateUserUnlockableHUSDAmount,
} from './actions'

export interface BonusState {
  activatedBonuses: UserBonus[]

  HUSDBonus: {
    unlockableBalance: string
    claimableBalance: string
    extraClaimableBalance: string
  }
}

export const initialState: BonusState = {
  activatedBonuses: [],
  HUSDBonus: {
    unlockableBalance: '0',
    claimableBalance: '0',
    extraClaimableBalance: '0',
  },
}

export const appSlice = createSlice({
  name: 'bonus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateActivatedBonuses, (state, { payload }) => {
        const { activatedBonuses } = payload
        state.activatedBonuses = activatedBonuses
        return state
      })
      .addCase(updateCurrentWagerInActivatedBonuses, (state, { payload }) => {
        const { currentWager, id } = payload
        const wageringProcessBonusIndex = state.activatedBonuses.findIndex(
          (bonus) =>
            bonus instanceof WageringConditionBonus &&
            bonus.isActiveWagering &&
            !bonus.condition.isExpired &&
            bonus.id === id,
        )

        if (wageringProcessBonusIndex >= 0) {
          const wageringProcessBonus = state.activatedBonuses[wageringProcessBonusIndex] as WageringConditionBonus
          wageringProcessBonus.currentWager = currentWager

          const cloneWageringProcessBonus = Object.assign(
            Object.create(Object.getPrototypeOf(wageringProcessBonus)),
            wageringProcessBonus,
          )
          state.activatedBonuses[wageringProcessBonusIndex] = cloneWageringProcessBonus
        }

        return state
      })

      .addCase(updateBonusToActiveWagering, (state, { payload }) => {
        const { id } = payload
        const wageringProcessBonusIndex = state.activatedBonuses.findIndex((bonus) => bonus.id === id)

        if (wageringProcessBonusIndex >= 0) {
          const wageringProcessBonus = state.activatedBonuses[wageringProcessBonusIndex] as WageringConditionBonus
          wageringProcessBonus.isActiveWagering = true

          const cloneWageringProcessBonus = Object.assign(
            Object.create(Object.getPrototypeOf(wageringProcessBonus)),
            wageringProcessBonus,
          )
          state.activatedBonuses[wageringProcessBonusIndex] = cloneWageringProcessBonus
        }

        return state
      })
      .addCase(updateUserHUSDBonus, (state, { payload }) => {
        const { claimableBalance, unlockableBalance, extraClaimableBalance } = payload

        state.HUSDBonus = {
          claimableBalance,
          unlockableBalance,
          extraClaimableBalance,
        }

        return state
      })
      .addCase(updateUserClaimableHUSDAmount, (state, { payload }) => {
        const { claimableBalance, extraClaimableBalance } = payload

        state.HUSDBonus.claimableBalance = claimableBalance
        state.HUSDBonus.extraClaimableBalance = extraClaimableBalance

        return state
      })

      .addCase(updateUserUnlockableHUSDAmount, (state, { payload }) => {
        const { unlockableBalance } = payload

        state.HUSDBonus.unlockableBalance = unlockableBalance

        return state
      })

    builder.addCase(resetUserBonus, (state) => {
      state.HUSDBonus = {
        unlockableBalance: '0',
        claimableBalance: '0',
        extraClaimableBalance: '0',
      }
      state.activatedBonuses = []

      return state
    })
  },
})

export default appSlice.reducer
