export const LOGO_URL = '/logo.png'
export const LOGO_ALT_URL = '/logo-new.png'
export const LOGO_TEXT_URL = '/logo-new-small.png'
export const LOGO_200_URL = '/logo-200.png'
export const LOGO_512_URL = '/logo-512.png'
export const LOGO_REFERRAL = '/images/referral/hunny-girls.png'

export const LOGO_WHEEL_DECOR = '/images/luckyspin/wheel-decor-hunny-female.png'
export const LOGO_WHEEL_TITLE = '/images/luckyspin/title-hunny.png'

export const LOGO_HUNNY_FEMALE = '/images/hunny-female.png'
export const LOGO_HUNNY_FEMALE_ALT = '/images/hunny-female-alt.png'

export const SUPPORT_MAIL_NAME = 'support@vipbet.io'
export const SUPPORT_MAIL_URL = 'mailto:support@vipbet.io'

export const PRODUCT_NAME = 'VipBet'
export const PRODUCT_NAME_ALT = 'VipBet'
export const PRODUCT_STABLE_COIN_NAME = 'VipBetDollar'
export const PRODUCT_URL = 'https://vipbet.dev/'

export const DEFAULT_META_TITLE = 'VipBet | The best crypto gaming platform'
export const DEFAULT_META_DESCRIPTION = 'VipBet.dev - The Best Crypto iGaming Platform'
export const DEFAULT_META_OG_IMAGE = ''

