import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  ONE: {
    coinGeckoInfo: {
      id: 'harmony',
    },
    logo: `${path}/one.png`,
    name: 'ONE',
    code: 'ONE',
    network: ChainIdEnum.ONE_TESTNET,
    isNative: true,
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.ONE_TESTNET,
    address: '0x0F233281661dC1799a944C2a6c8Ed04d076f5570',
    decimals: 6,
    isStable: true,
  },
}

export default tokens
