import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  BNB: {
    coinGeckoInfo: {
      id: 'binancecoin',
    },
    logo: `${path}/bnb.png`,
    name: 'BNB',
    code: 'BNB',
    network: ChainIdEnum.BNB_TESTNET,
    isNative: true,
    decimals: 18,
  },
  HUNNY: {
    coinGeckoInfo: {
      id: 'pancake-hunny',
    },
    logo: `${path}/hunny.png`,
    name: 'HUNNY',
    code: 'HUNNY',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0x626BaE0ACE95fA9076d9D7803348E2307275bd24',
    decimals: 18,
  },
  USDT: {
    coinGeckoInfo: {
      id: 'tether',
    },
    logo: `${path}/usdt.png`,
    name: 'USDT',
    code: 'USDT',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0xE032c45447e7D398e2f2F9F7Cd19202E7D8Bf844',
    decimals: 18,
    isStable: true,
  },
  CAKE: {
    coinGeckoInfo: {
      id: 'pancakeswap-token',
    },
    logo: `${path}/cake.png`,
    name: 'CAKE',
    code: 'CAKE',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0x31d060249e87bd61b94ecD01fB75a8A0Cd129307',
    decimals: 18,
  },
  BUSD: {
    coinGeckoInfo: {
      id: 'binance-usd',
    },
    logo: `${path}/busd.png`,
    name: 'BUSD',
    code: 'BUSD',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0x384F15cFFa429B7294056aA63ee16414c2681028',
    decimals: 18,
    isStable: true,
  },
  LOVE: {
    coinGeckoInfo: {
      id: 'hunny-love-token',
    },
    logo: `${path}/love.png`,
    name: 'LOVE',
    code: 'LOVE',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0x7825749af0E73c23CCC703efcc9946F01De66f39',
    decimals: 18,
  },
  BABY: {
    coinGeckoInfo: {
      id: 'babyswap',
    },
    logo: `${path}/baby.png`,
    name: 'BABY',
    code: 'BABY',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0xd9f171df7329845e58a2267ec43402a27c9567a9',
    decimals: 18,
  },
  VAI: {
    coinGeckoInfo: {
      id: 'vai',
    },
    logo: `${path}/vai.png`,
    name: 'VAI',
    code: 'VAI',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0xEa5a5D7d7269cFE7eB46d6b4F17B8896660036D7',
    decimals: 18,
  },
  XVS: {
    coinGeckoInfo: {
      id: 'venus',
    },
    logo: `${path}/xvs.png`,
    name: 'XVS',
    code: 'XVS',
    network: ChainIdEnum.BNB_TESTNET,
    address: '0x80A602426bCbC8A6Db0e33589Ea3D46b867F87BC',
    decimals: 18,
  },
}

export default tokens

