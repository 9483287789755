import { createSlice } from '@reduxjs/toolkit'
import { FetchingStatus } from 'config/constants'
import { DepositedTokenInfo, GameTag, ProviderInfo, ScreenTag } from 'config/types'
import { Game, GameDetail } from 'config/types/game'
import { TokenUsdPriceResponse } from 'services/types'
import { WelcomePackage } from 'config/types/bonus/welcomePackage'
import { PinnedPromotion } from 'config/types/promotion'
import { ProfileTierInfo } from 'config/types/profile'
import {
  initMetadata,
  setAuthModalOpen,
  setInitializeSiteStatus,
  setIsInGame,
  setIsSigned,
  setMenuOpen,
  setPlayingGame,
  setSearchOpen,
  setSidebarOpen,
  updatePopularSearchGames,
  updateTokenUsdPrices,
} from './actions'

export interface AppState {
  providers: ProviderInfo[]
  tokenInfoes: DepositedTokenInfo[]
  tokenUsdPrices: TokenUsdPriceResponse
  networkIds: string[]
  tags: GameTag[]
  defaultScreenTags: ScreenTag[]
  serverTimeMargin: number
  serverTime: number
  isMenuOpen: boolean
  isSidebarOpen: boolean
  isSearchOpen: boolean
  isSigned: boolean
  signedAtTime: number
  popularSearchGames: Game[]
  isInGame: boolean
  playingGame: GameDetail
  initialSiteStatus: FetchingStatus
  isAuthModalOpen: boolean

  welcomePackage: WelcomePackage
  pinnedPromotions: PinnedPromotion[]
  tiers: ProfileTierInfo[]
}

export const initialState: AppState = {
  providers: [],
  initialSiteStatus: FetchingStatus.Fetching,
  tags: [],
  defaultScreenTags: [],
  serverTimeMargin: 0,
  serverTime: null,
  tokenInfoes: [],
  networkIds: [],
  isMenuOpen: false,
  isSidebarOpen: false,
  isSearchOpen: false,
  isSigned: null,
  isAuthModalOpen: false,
  tokenUsdPrices: {},
  popularSearchGames: [],
  signedAtTime: null,
  isInGame: false,
  playingGame: null,

  welcomePackage: null,
  pinnedPromotions: [],
  tiers: [],
}

export const appSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initMetadata, (state, { payload }) => {
        const { data } = payload
        state.defaultScreenTags = data.defaultScreenTags || []
        state.providers = data.providers || []
        state.tokenInfoes = data?.tokenInfoes
        state.networkIds = data?.networkIds
        state.tags = data?.tags
        state.serverTime = data?.serverTime
        const serverTimeMargin = new Date().getTime() - (data?.serverTime || 0)
        state.serverTimeMargin = Math.abs(serverTimeMargin) > 10000 ? serverTimeMargin : 0
        state.welcomePackage = data.welcomePackage
        state.pinnedPromotions = data.pinPromotions
        state.tiers = data.tiers

        return state
      })

      .addCase(setMenuOpen, (state, { payload }) => {
        const { isOpen } = payload
        state.isMenuOpen = isOpen
        return state
      })

      .addCase(setAuthModalOpen, (state, { payload }) => {
        const { isOpen } = payload
        state.isAuthModalOpen = isOpen
        return state
      })

      .addCase(setSidebarOpen, (state, { payload }) => {
        const { isOpen } = payload
        state.isSidebarOpen = isOpen
        return state
      })

      .addCase(setSearchOpen, (state, { payload }) => {
        const { isOpen } = payload
        state.isSearchOpen = isOpen
        return state
      })
      .addCase(setIsSigned, (state, { payload }) => {
        const { isSigned, atTime } = payload
        state.isSigned = isSigned
        state.signedAtTime = atTime || null
        return state
      })

      .addCase(updateTokenUsdPrices, (state, { payload }) => {
        state.tokenUsdPrices = { ...state.tokenUsdPrices, ...payload.data }
        return state
      })

      .addCase(updatePopularSearchGames, (state, { payload }) => {
        state.popularSearchGames = payload.games
        return state
      })
      .addCase(setIsInGame, (state, { payload }) => {
        state.isInGame = payload
        return state
      })
      .addCase(setPlayingGame, (state, { payload }) => {
        state.playingGame = payload.game
        return state
      })
      .addCase(setInitializeSiteStatus, (state, { payload }) => {
        state.initialSiteStatus = payload.status
        return state
      })
  },
})

export default appSlice.reducer
