import { createSlice } from '@reduxjs/toolkit'
import {
  addPendingDepositTransaction,
  addPendingWithdrawTransaction,
  clearPendingTransaction,
  removePendingDepositTransaction,
  removePendingWithdrawTransaction,
} from './action'

export interface TransactionState {
  pendingWithdrawTransactionCode: string[]
  pendingDepositTransactionHash: string[]
}

export const initialState: TransactionState = {
  pendingWithdrawTransactionCode: [],
  pendingDepositTransactionHash: [],
}

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(clearPendingTransaction, (state) => {
      state.pendingWithdrawTransactionCode = []
      state.pendingDepositTransactionHash = []
      return state
    })
    builder.addCase(addPendingWithdrawTransaction, (state, { payload }) => {
      state.pendingWithdrawTransactionCode = [payload.code, ...(state.pendingWithdrawTransactionCode || [])]
      return state
    })
    builder.addCase(removePendingWithdrawTransaction, (state, { payload }) => {
      const index = state.pendingWithdrawTransactionCode?.indexOf(payload.code)
      if (index > -1) {
        // only splice array when item is found
        state.pendingWithdrawTransactionCode.splice(index, 1) // 2nd parameter means remove one item only
        state.pendingWithdrawTransactionCode = [...state.pendingWithdrawTransactionCode]
      }

      return state
    })

    builder.addCase(addPendingDepositTransaction, (state, { payload }) => {
      if (!state.pendingDepositTransactionHash.includes(payload.hash))
        state.pendingDepositTransactionHash = [payload.hash, ...(state.pendingDepositTransactionHash || [])]
      return state
    })
    builder.addCase(removePendingDepositTransaction, (state, { payload }) => {
      const index = state.pendingDepositTransactionHash?.indexOf(payload.hash)
      if (index > -1) {
        // only splice array when item is found
        state.pendingDepositTransactionHash.splice(index, 1) // 2nd parameter means remove one item only
        state.pendingDepositTransactionHash = [...state.pendingDepositTransactionHash]
      }

      return state
    })
  },
})

export default transactionSlice.reducer
