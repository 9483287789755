import { createSlice } from '@reduxjs/toolkit'
import { FeaturedGamesMap } from 'config/types/game'
import { LuckySpinStatusProps } from 'config/types/luckyspin'
import { ProfileTierInfo, WagerInfo } from 'config/types/profile'
import { getBalanceTokenKey } from 'utils'
import {
  initialProfile,
  resetProfile,
  updateBalances,
  updateFavoriteGames,
  updateEligibleWageringBonusGames,
  updateMyGameLevelStats,
  updateMyTier,
  updateUserFreeLuckySpin,
  updateUserWelcomePackageInfo,
  updateFavoriteGame,
} from './actions'

export interface ProfileState {
  balances: { [tokenKey: string]: string }
  tier: ProfileTierInfo
  wager: WagerInfo
  luckySpin: LuckySpinStatusProps
  welcomePackExpiredAt: number
  hasClaimedWelcomePackage: boolean
  featuredGames: FeaturedGamesMap

  // TODO use it in future
  // eslint-disable-next-line @typescript-eslint/ban-types
  userSettings: {}
}

export const initialProfileState: ProfileState = {
  balances: {},
  tier: null,
  hasClaimedWelcomePackage: false,
  wager: {
    percentage: 0,
    target: 0,
    score: 0,
  },
  luckySpin: {
    nextSpinTime: 0,
    amount: 0,
  },
  welcomePackExpiredAt: 0,
  userSettings: {},
  featuredGames: {
    wageringBonusGames: {},
    favoriteGames: {},
  },
}

export const profileSlice = createSlice({
  name: 'Profile',
  initialState: initialProfileState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initialProfile, (state, { payload }) => {
      const { balances, profile, welcomePackage } = payload.data

      state.tier = profile.tier
      state.wager = profile.wager
      state.welcomePackExpiredAt = welcomePackage.expiredAt
      state.hasClaimedWelcomePackage = welcomePackage.isClaimed

      balances.forEach((item) => {
        const tokenName = getBalanceTokenKey(item.network, item.currency)
        state.balances[tokenName] = item.amount
      })
      return state
    })

    builder.addCase(updateBalances, (state, { payload }) => {
      const { data } = payload

      data.forEach((item) => {
        const tokenName = getBalanceTokenKey(item.network, item.currency)
        state.balances[tokenName] = item.amount
      })
      return state
    })

    builder.addCase(updateMyTier, (state, { payload }) => {
      const { data } = payload

      state.tier = data.tier
      return state
    })
    builder.addCase(updateMyGameLevelStats, (state, { payload }) => {
      const { data } = payload

      state.wager = data
      return state
    })
    builder.addCase(updateUserWelcomePackageInfo, (state, { payload }) => {
      const { expiredAt, isClaimed } = payload

      state.welcomePackExpiredAt = expiredAt
      state.hasClaimedWelcomePackage = isClaimed
      return state
    })
    builder.addCase(updateEligibleWageringBonusGames, (state, { payload }) => {
      const { eligibleWageringBonusGames } = payload
      state.featuredGames.wageringBonusGames = eligibleWageringBonusGames
      return state
    })
    builder.addCase(updateUserFreeLuckySpin, (state, { payload }) => {
      const { nextSpinTime, amount } = payload

      if (!state.luckySpin) {
        state.luckySpin = initialProfileState.luckySpin
      }

      state.luckySpin.amount = amount
      state.luckySpin.nextSpinTime = nextSpinTime
      return state
    })
    builder.addCase(updateFavoriteGames, (state, { payload }) => {
      const { favoriteGames } = payload

      state.featuredGames.favoriteGames = favoriteGames
      return state
    })
    builder.addCase(updateFavoriteGame, (state, { payload }) => {
      const { gameCode, isFavorited } = payload

      state.featuredGames.favoriteGames[gameCode] = isFavorited
      return state
    })
    builder.addCase(resetProfile, (state) => {
      state.balances = {}
      state.tier = null
      state.wager = {
        percentage: 0,
        target: 0,
        score: 0,
      }
      state.luckySpin.amount = 0
      state.luckySpin.nextSpinTime = 0
      state.welcomePackExpiredAt = 0

      return state
    })
  },
})

export default profileSlice.reducer
