import { Token } from 'config/types'
import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens: Record<string, Token> = {
  FTM: {
    coinGeckoInfo: {
      id: 'fantom',
    },
    logo: `${path}/ftm.png`,
    name: 'FTM',
    code: 'FTM',
    network: ChainIdEnum.FTM,
    isNative: true,
    decimals: 18,
  },
  USDC: {
    coinGeckoInfo: {
      id: 'usd-coin',
    },
    logo: `${path}/usdc.png`,
    name: 'USDC',
    code: 'USDC',
    network: ChainIdEnum.FTM,
    address: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    decimals: 6,
    isStable: true,
  },
  BOO: {
    coinGeckoInfo: {
      id: 'spookyswap',
    },
    logo: `${path}/boo.png`,
    name: 'BOO',
    code: 'BOO',
    network: ChainIdEnum.FTM,
    address: '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
    decimals: 18,
  },
}

export default tokens
